import {BrowserRouter as Router} from 'react-router-dom'
import {EmailAuthProvider} from 'firebase/auth'

import {
    FirebaseAuthDelegate,
    CircularProgressCenter,
    FirebaseLoginView,
    FireCMS,
    NavigationRoutes,
    Scaffold,
    SideEntityDialogs,
    useFirebaseStorageSource, useFirebaseAuthDelegate, useFirestoreDataSource, useInitialiseFirebase,
} from '@camberi/firecms'

import 'typeface-rubik'
import 'typeface-space-mono'
import {CssBaseline, ThemeProvider} from '@mui/material'
import {firebaseConfig} from './config/firebase.config'
import {navigation} from "./config/navigation";
import {authenticator} from "./config/authenticator";
import {defaultTheme} from "./config/theme";
import {getAuth, connectAuthEmulator} from "firebase/auth";
import {getFirestore, connectFirestoreEmulator} from "firebase/firestore";


export default function App() {

    const {
        firebaseApp,
        firebaseConfigLoading,
        configError,
        firebaseConfigError,
    } = useInitialiseFirebase({firebaseConfig, onFirebaseInit: async (config) => {
        }})
    const signInOptions = [EmailAuthProvider.PROVIDER_ID]
    const authDelegate: FirebaseAuthDelegate = useFirebaseAuthDelegate({
        firebaseApp,
        signInOptions
    })
    const dataSource = useFirestoreDataSource({
        firebaseApp: firebaseApp,
    })
    const storageSource = useFirebaseStorageSource({firebaseApp: firebaseApp})

    if (configError) {
        return <div> {configError} </div>
    }

    if (firebaseConfigError) {
        return (
            <div>
                It seems like the provided Firebase config is not correct. If
                you are using the credentials provided automatically by Firebase
                Hosting, make sure you link your Firebase app to Firebase
                Hosting.
            </div>
        )
    }

    if (firebaseConfigLoading || !firebaseApp) {
        return <CircularProgressCenter/>
    }

    return (
        <Router>
            <FireCMS
                navigation={navigation}
                authDelegate={authDelegate}
                authentication={authenticator}
                dataSource={dataSource}
                storageSource={storageSource}
                locale="de"
                dateTimeFormat="dd. MMMM yyyy HH:mm"
                entityLinkBuilder={({entity}) =>
                    `https://console.firebase.google.com/project/${firebaseApp.options.projectId}/firestore/data/${entity.path}/${entity.id}`
                }
            >
                {({context, mode, loading}) => {
                    const theme = defaultTheme;

                    let component
                    if (loading) {
                        component = <CircularProgressCenter/>
                    } else if (!context.authController.canAccessMainView) {
                        component = (
                            <FirebaseLoginView
                                logo={'/assets/lukb-ictinfo.png'}
                                signInOptions={[
                                    {
                                        provider: EmailAuthProvider.PROVIDER_ID,
                                    },
                                ]}
                                firebaseApp={firebaseApp}
                                authDelegate={authDelegate}
                            />
                        )
                    } else {
                        component = (
                            <Scaffold
                                name={
                                    'LUKB ICT Info: Admin UI / ' +
                                    process.env.REACT_APP_ENV?.toUpperCase()
                                }
                                logo={'/assets/lukb.jpg'}
                            >
                                <NavigationRoutes/>
                                <SideEntityDialogs/>
                            </Scaffold>
                        )
                    }

                    return (
                        <ThemeProvider theme={theme}>
                            <CssBaseline/>
                            {component}
                        </ThemeProvider>
                    )
                }}
            </FireCMS>
        </Router>
    )
}
