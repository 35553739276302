import { buildSchema } from '@camberi/firecms'
import { Role } from '../types/role.types'

export const roleSchema = buildSchema<Role>({
    name: 'Rolle',
    customId: true,
    properties: {
        name: {
            title: 'Name',
            dataType: 'string',
            columnWidth: 100,
        },
        roles_read: {
            title: '🔍 roles',
            dataType: 'boolean',
        },
        roles_write: {
            title: '📝 roles',
            dataType: 'boolean',
        },
        users_read: {
            title: '🔍 users',
            dataType: 'boolean',
        },
        users_read_own: {
            title: '🔍👤 users',
            dataType: 'boolean',
        },
        users_write: {
            title: '📝 users',
            dataType: 'boolean',
        },
        itsystem_read: {
            title: '🔍 itsystem',
            dataType: 'boolean',
        },
        itsystem_write: {
            title: '📝 itsystem',
            dataType: 'boolean',
        },
        news_read: {
            title: '🔍 news',
            dataType: 'boolean',
        },
        news_write: {
            title: '📝 news',
            dataType: 'boolean',
        },
        newsimage_read: {
            title: '🔍 newsimage',
            dataType: 'boolean',
        },
        newsimage_write: {
            title: '📝 newsimage',
            dataType: 'boolean',
        },
    },
})
