import {buildCollection} from "@camberi/firecms";
import {itSystemSchema} from "../schemas/itsystem.schema";

export const itSystemCollection = buildCollection({
    group: 'Status',
    path: 'itsystem',
    schema: itSystemSchema,
    name: 'IT Systeme',
    defaultSize: 's',
    initialSort: ['name', 'asc'],
    pagination: true,
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})