import { buildSchema, buildProperty } from '@camberi/firecms'
import { NewsItem } from '../types/newsitem.types'

export const newsSchema = buildSchema<NewsItem>({
    name: 'News',
    properties: {
        name: {
            title: 'Name',
            validation: { required: true },
            dataType: 'string',
        },

        description: {
            title: 'Beschreibung',
            dataType: 'string',
            config: {
                markdown: true,
            },
        },
        published: ({ values }) =>
            buildProperty({
                title: 'Sichtbar',
                dataType: 'boolean',
                columnWidth: 130,
            }),
        dateLastStatusChange: {
            title: 'Datum: Letzte Änderung',
            dataType: 'timestamp',
        },
    },
})
