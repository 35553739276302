import {buildCollection} from "@camberi/firecms";
import {newsSchema} from "../schemas/news.schema";

export const newsCollection = buildCollection({
    group: 'Status',
    path: 'news',
    schema: newsSchema,
    name: 'News',
    defaultSize: 's',
    initialSort: ['name', 'asc'],
    pagination: true,
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})