import {buildCollection} from "@camberi/firecms";
import {userSchema} from "../schemas/user.schema";

export const usersCollection = buildCollection({
    group: '🔥 Superadmin-Zone',
    path: 'users',
    schema: userSchema,
    name: 'Benutzer',
    defaultSize: 's',
    initialSort: ['lastname', 'asc'],
    pagination: true,
    textSearchEnabled: true,
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})