import { NavigationBuilder } from '@camberi/firecms'
import { usersCollection } from '../collections/users.collection'
import { rolesCollection } from '../collections/roles.collection'
import { itSystemCollection } from '../collections/itsystem.collection'
import { newsCollection } from '../collections/news.collection'
import { newsImageCollection } from '../collections/newsimage.collection'

export const navigation: NavigationBuilder = async () => {
    return {
        collections: [
            itSystemCollection,
            newsCollection,
            newsImageCollection,
            usersCollection,
            rolesCollection,
        ],
    }
}
