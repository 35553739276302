import {buildCollection} from "@camberi/firecms";
import { newsImageSchema } from "../schemas/newsimage.schema";

export const newsImageCollection = buildCollection({
    group: 'Status',
    path: 'newsimage',
    schema: newsImageSchema,
    name: 'News Bild',
    defaultSize: 's',
    initialSort: ['name', 'asc'],
    pagination: true,
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})