import { buildSchema, buildProperty } from '@camberi/firecms'
import { ITSystem } from '../types/itsystem.types'

export const itSystemSchema = buildSchema<ITSystem>({
    name: 'System',
    properties: {
        name: {
            title: 'Name',
            validation: { required: true },
            dataType: 'string',
        },

        description: {
            title: 'Beschreibung',
            dataType: 'string',
            config: {
                markdown: true,
            },
        },
        status: {
            title: 'System-Status',
            validation: { required: true },
            dataType: 'string',
            config: {
                enumValues: {
                    ok: 'OK',
                    performanceProblem: 'Performance Probleme',
                    partialOutage: 'Teilausfall',
                    fullOutage: 'Ausfall',
                    maintenance: 'Wartungsarbeit',
                },
            },
            columnWidth: 150,
        },
        published: ({ values }) =>
            buildProperty({
                title: 'Sichtbar',
                dataType: 'boolean',
                columnWidth: 130,
            }),
        dateLastStatusChange: {
            title: 'Datum: Letzte Änderung',
            dataType: 'timestamp',
        },
    },
})
