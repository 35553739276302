import {buildCollection} from "@camberi/firecms";
import {roleSchema} from "../schemas/role.schema";

export const rolesCollection = buildCollection({
    group: '🔥 Superadmin-Zone',
    path: 'roles',
    schema: roleSchema,
    name: 'Rollen',
    defaultSize: 'xs',
    initialSort: ['name', 'asc'],
    pagination: true,
    permissions: ({ authController }) => ({
        edit: true,
        create: true,
        delete: true,
    }),
    callbacks: {
        onPreSave: async (props) => {
            return {
                ...props.values,
                lastEditor: props.context.authController.user.uid,
            }
        },
    },
})