import {Authenticator} from "@camberi/firecms";
import {User as FirebaseUser} from "@firebase/auth";
import {userSchema} from "../schemas/user.schema";

export const authenticator: Authenticator<FirebaseUser> = async ({ user, dataSource}) => {
    if (!user?.uid) return false
    const entity = await dataSource.fetchEntity({
        path: `/users`,
        entityId: user.uid,
        schema: userSchema,
    })

    if (entity?.values?.isAdmin === true) return true
    throw new Error(
        'ERROR: Only admins allowed. Please contact your developer!'
    )
}