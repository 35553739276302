import { buildSchema, buildProperty } from '@camberi/firecms'
import { NewsImage } from '../types/newsimage.types'

export const newsImageSchema = buildSchema<NewsImage>({
    name: 'News-Bild',
    properties: {
        name: {
            title: 'Bild-Name',
            validation: { required: true },
            dataType: 'string',
        },
        image: buildProperty({
            // The `buildProperty` method is an utility function used for type checking
            title: 'Bild',
            dataType: 'string',
            config: {
                storageMeta: {
                    mediaType: 'image',
                    storagePath: 'images/news',
                    acceptedFiles: ['image/*'],
                },
            },
        }),
    },
})
